import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent, 
} from '@chakra-ui/react'   

function ModalDialog({isOpen,onClose,component}) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          {component}
        
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalDialog;