import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import logo from "assets/img/blue-logo.png";  

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {

  return (
    <Flex align='center' direction='column'>
      <img width={175} my='32px' src={logo}/>
      <HSeparator mb='20px' mt='20px' />
    </Flex>
  );
}

export default SidebarBrand;
