import { Redirect } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("auth");
  if (!token) {
    return <Redirect to="/" replace></Redirect>;
  }
  return children;
};

export default ProtectedRoute;
