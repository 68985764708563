export const columnsDataCheck = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "QR CODE",
    accessor: "qr_code",
  },
  {
    Header: "ACTION",
    accessor: 'action',
  },
];
