export const columnsData = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "NAME",
      accessor: "user.name",
    },
    {
      Header: "EMAIL",
      accessor: "user.email",
    },
    {
      Header: "PHONE",
      accessor: "user.phone",
    }
  ];