import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdLock,
} from "react-icons/md";
import { FiUsers } from 'react-icons/fi'

import { FaUserAlt } from 'react-icons/fa'
// Admin Imports
import MainDashboard from "views/admin/default";
import DataTables from "views/admin/dataTables";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import ForgotPassword from "views/auth/forgotPassword"; 
import ViewCoins from "views/admin/viewCoins/viewCoins";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/dashboard",
    exact:true,
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Users Listing",
    layout: "/admin",
    exact:true,
    icon: <Icon as={FiUsers} width='20px' height='20px' color='inherit' />,
    path: "/users-listing",
    component: DataTables,
  },

  {
    name: "",
    layout: "/admin",
    icon: <Icon as={FaUserAlt} width='20px' height='20px' color='inherit' />,
    exact:true,
    path: "/coin-detail/:id",
    component: ViewCoins,
  },
  
 
];

const authRoutes = [
  {
    name: "Sign In",
      layout: "/auth",
      path: "/",
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: SignInCentered,
     },
    {
      name: "Forgot Password",
      layout: "/auth",
      path: "/forgot-password",
      icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
      component: ForgotPassword, 
    },
]

export  {routes,authRoutes};
