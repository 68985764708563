import React, { useEffect, useState } from 'react'

import {
    Flex,
    Text,
    useColorModeValue,
 Box, SimpleGrid, Image
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import { fetchData } from 'services/http';
import { useParams } from 'react-router-dom';
import UserDetailTable from './components/table';
import { columnsData } from './variables/columnsData';
import Pagination from 'components/pagination/pagination';

const ViewCoins = () => {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [details, setDetails] = useState(null);
    const [history, setHistory] = useState([]);
    const [search, setSearch] = useState("");
    const [initialpage, setInitialpage] = useState(1);
    const [totalcount, setTotalcount] = useState(null);
    const pageCount = Math.ceil(totalcount / 10);
    const params = useParams();
    const { id } = params;


    const getValue = (value) => {
        setSearch(value);
    }

    const pageChange = (pageNum) => {
        setInitialpage(pageNum);
    };

    useEffect(() => {
        const getCoinDetail = async () => {
            try {
                const response = await fetchData(`coins/${id}?search=${search}`, 'get', "", "");
                if (response?.data?.status === 200) {
                    setDetails(response?.data?.coin?.data[0])
                    setHistory(response?.data?.coin?.data[0]?.coin_histories)
                    setTotalcount(response?.data?.total_coins_in_history)
                }
            }
            catch (error) {
                return error
            }
        }
        getCoinDetail();
    }, [search])

    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <Card
                    direction='column'
                    w='100%'
                    px='0px'
                    overflowX={{ sm: "scroll", lg: "hidden" }}>
                    <Flex px='25px' justify='space-between' align='center'>
                        <Text
                            color={textColor}
                            fontSize='22px'
                            fontWeight='700'
                            lineHeight='100%'>
                            Coin Details
                        </Text>
                        <Flex>
                        </Flex>
                    </Flex>
                    <SimpleGrid>
                        <Box pt={{ base: "130px", md: "80px", xl: "30px" }}>
                            <Flex px='25px' justify='space-between' align='center'>
                                <Text
                                    color={textColor}
                                    fontSize='18px'
                                    fontWeight='500'
                                    lineHeight='100%'>
                                    {details?.name}
                                </Text>
                                <Flex>
                                    <Image src={`https://helpcoins-api.customerdemourl.com/storage/qr_codes/${details?.qr_code}`} alt='Dan Abramov' height={100} width={100} />

                                </Flex>
                            </Flex>
                        </Box>
                    </SimpleGrid>

                </Card>
                <Box pt={{ base: "130px", md: "80px", xl: "50px" }}>
                    <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
                        
                                {
                        history?.length>0 && <Card
                        direction='column'
                        w='100%'
                        px='0px'
                        overflowX={{ sm: "scroll", lg: "hidden" }}>  <UserDetailTable columnsData={columnsData} tableData={history} getvalue={getValue} />
                        </Card>
                           
                                }
                            <div>
                                {history?.length >= 1 ? (
                                    <Pagination
                                        initialpage={initialpage}
                                        onpage={pageChange}
                                        pageCount={pageCount}
                                    />
                                ) : null}
                            </div>
                    </SimpleGrid>
                </Box>
            </Box>
        </>
    )
}
export default ViewCoins