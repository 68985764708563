import {
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Select,
  Input,InputGroup,InputLeftElement,IconButton
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { FiEdit } from 'react-icons/fi'
import { SearchIcon } from "@chakra-ui/icons";

// Custom components
import Card from "components/card/Card";
import ModalDialog from "theme/components/modal";
import AddCoins from "views/admin/addCoins/addCoins";
import { useHistory } from "react-router-dom";
export default function CheckTable(props) {
  const { columnsData, tableData } = props;
  const [show, setShow] = React.useState(false);
  const [search,setSearch]=useState("");
  const [filter,setFilter]=useState("");
  const navigate = useHistory();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");

  useEffect(()=>{
      props.detectchange(show)
  },[show])

  return (
    <>
    {<ModalDialog isOpen={show} onClose={ ()=> setShow(false)} component={<AddCoins onClose={ ()=> setShow(false)}/>} />}
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Coins Table
        </Text>
        <Flex>
      <InputGroup w={{ base: "100%", md: "200px" }} me="10px">
      <InputLeftElement
      onClick={()=>props.getvalue(search,filter)}
        children={
          <IconButton
            bg='inherit'
            borderRadius='inherit'
            _hover='none'
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={
              <SearchIcon color={searchIconColor} w='15px' h='15px' />
            }></IconButton>
        }
      />
      <Input
        variant='search'
        fontSize='sm'
        bg={ inputBg}
        color={inputText}
        fontWeight='500'
        onChange={(e)=>setSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.getvalue(e.target.value,filter);
          }
        }}
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={ "30px"}
        placeholder={ "Search..."}
      />
    </InputGroup> 
     <Select placeholder='Select option' width={150} me="10px" onChange={(e)=>props.getvalue(search,e.target.value)}>
  <option value='1'>Active Coins</option>
  <option value='0'>In-Active Coins</option>
</Select>
        <Button colorScheme='blue' onClick={ ()=> setShow(true) }>Add Coin</Button>
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    // justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                    <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
                  </Flex>
                  
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index} onClick={()=>navigate.push(`/admin/coin-detail/${row.values.id}`)} cursor="pointer">
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "NAME") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ID" ) {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value===0?"In-Active":"Active"}
                      </Text>
                    );
                  } else if (cell.column.Header === "QR CODE") {
                    data = (
                      <img height={70} width={70} src={`https://helpcoins-api.customerdemourl.com/storage/qr_codes/${cell?.value}`}/>
                    );
                  }
                  else{
                    data=(
                      <Icon as={FiEdit} width='20px' height='20px' color='inherit'onClick={()=>console.log("hi")}/>
                    )
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
    </>
  );
}
