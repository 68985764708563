export const columnsDataDevelopment = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
];
