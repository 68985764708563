import { Redirect } from "react-router-dom";

const PublicRoutes = ({ children }) => {
  const token = localStorage.getItem("auth");
  if (token) {
    return (
      <>
        <Redirect to="/admin/dashboard" replace />
      </>
    );
  }
  return children;
};
export default PublicRoutes;
