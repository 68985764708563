import axios from "axios";

export const axiosInstance = axios.create();

const getLocalStorage = () => {
  return localStorage.getItem('auth')
}

axiosInstance.interceptors.request.use(function (config) {
  const token = getLocalStorage();
    config.baseURL=process.env.REACT_APP_API_URL
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });