// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React,{useEffect,useState} from "react";
import {
  MdAttachMoney,
} from "react-icons/md";
import { FiUsers } from 'react-icons/fi'

import CheckTable from "views/admin/default/components/CheckTable";
import {
  columnsDataCheck,
} from "views/admin/default/variables/columnsData";
import { fetchData } from "services/http";
import Pagination from "components/pagination/pagination";
import Card from "components/card/Card";

export default function UserReports() {
  const [stats,setStats]=useState(null);
  const [coinslist,setCoinList]=useState([]);
  const [initialpage, setInitialpage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const pageCount = Math.ceil(totalcount / 10);
  const [toggle,setToggle]=useState(true);
  const [search,setSearch]=useState("");
  const [filter,setFilter]=useState("")

  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };

  useEffect(() => {
    const getCoinlist = async () => {
     search!==""? setInitialpage(1):setInitialpage(initialpage) ;
      try {
        const response = await fetchData(`coin-list?perPage=10&page=${initialpage}&search=${search}&status=${filter}`, 'get', "", "");
        if(response?.data?.status===200){
            setCoinList(response?.data?.data?.data);
            setTotalcount(response?.data?.data?.total)
        }
      }
      catch (error) {
        return error
      }
    }
    getCoinlist();
  }, [initialpage,toggle,search,filter])

  useEffect(() => {
    const getStats = async () => {
      try {
        const response = await fetchData(`coin-stats`, 'get', "", "");
        if(response?.data?.status===200){
          setStats(response?.data)
        }
      }
      catch (error) {
        return error
      }
    }
    getStats();
  }, [])

  const change = (value) =>{
    if(!value){
      setToggle(value)
    }
  }

  const getValue = (value,value1) =>{
    setSearch(value);
    setFilter(value1)
  }

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FiUsers} color={brandColor} />
              }
            />
          }
          name='Users'
          value={stats?.user_count}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Active Coins'
          value={stats?.coin_active_count}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='In-Active Coins'
          value={stats?.coin_in_active_count}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <Card>
        <CheckTable columnsData={columnsDataCheck} tableData={coinslist} detectchange = {change} getvalue={getValue}/>
        <div>
            {coinslist?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
          </div>
          </Card>
      </SimpleGrid>

    </Box>
  );
}
