import React,{useState} from 'react';

import {
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    Button,
    FormLabel,
    Input
  } from '@chakra-ui/react'  
  
import { fetchData } from 'services/http';
import { toast } from 'react-toastify';

const AddCoins = ({onClose}) => {
    const [coinname,setCoinName]=useState("");

    const generateCoin =async () => {
        const data={
            name:coinname
        }
        try {
            const response = await fetchData(`generate-token`, 'post', data, "");
            console.log(response)
            if(response?.data?.status==200){
                toast.success(response?.data?.message);
                onClose();
            }
          }
          catch (error) {
            return error
          }
    }

    return(
        <>
        <ModalHeader>Add Coin</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Coin name</FormLabel>
            <Input  placeholder="Coin name" onChange={(e)=>setCoinName(e.target.value)} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={generateCoin} disabled={!coinname}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
        </>
    )
}

export default AddCoins