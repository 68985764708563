import React,{ useState,useEffect } from "react";

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import {
  columnsDataDevelopment
} from "views/admin/dataTables/variables/columnsData";
import { fetchData } from "services/http";
import Pagination from "components/pagination/pagination";
import Card from "components/card/Card";

export default function Settings() {
  const [userlist,setUserList]=useState([]);
  const [initialpage, setInitialpage] = useState(1);
  const [totalcount, setTotalcount] = useState(null);
  const pageCount = Math.ceil(totalcount / 10);
  const [search,setSearch]=useState("");

  const pageChange = (pageNum) => {
    setInitialpage(pageNum);
  };

  

  useEffect(() => {
    const getUserlist = async () => {
      try {
        const response = await fetchData(`users-list?perPage=10&page=${initialpage}&search=${search}`, 'get', "", "");
        if(response?.data?.status===200){
          setUserList(response?.data?.data?.data);
            setTotalcount(response?.data?.data?.total)
        }
      }
      catch (error) {
        return error
      }
    }
    getUserlist();
  }, [initialpage,search])

  const getValue = (value) =>{
    setSearch(value);
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <Card>
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={userlist}
          getvalue={getValue}
          
        />
        <div>
            {userlist?.length >= 1 ? (
              <Pagination
                initialpage={initialpage}
                onpage={pageChange}
                pageCount={pageCount}
              />
            ) : null}
          </div>
          </Card>
      </SimpleGrid>
      
    </Box>
  );
}
